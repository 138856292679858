:root { 
--green_300:#74b684; 
--black_900_7f:#0000007f; 
--light_blue_300:#61dafb; 
--pink_900:#822025; 
--pink_901:#671e22; 
--red_400:#e5484d; 
--gray_50_87:#f8fafc87; 
--gray_900_0c:#1018280c; 
--light_green_600:#68bd45; 
--light_green_601:#6cac48; 
--teal_900:#172838; 
--deep_orange_500:#f16529; 
--cyan_A700:#00acd3; 
--gray_400:#c7c7c7; 
--blue_900:#1f5b98; 
--gray_401:#c2bfbf; 
--gray_802:#383838; 
--gray_803:#3d3d3d; 
--gray_800:#3f3f3f; 
--gray_801:#4e4e4e; 
--bluegray_801:#394d54; 
--bluegray_800:#3e4a5d; 
--indigo_200:#9cb4d0; 
--bluegray_400:#679fb8; 
--indigo_601:#336791; 
--indigo_600:#286d97; 
--bluegray_802:#35495e; 
--gray_51:#f5f3ff; 
--red_700:#d82c20; 
--red_300:#d79870; 
--gray_50:#f8fafc; 
--teal_400:#42b883; 
--teal_800:#0C57A2;
--light_green_500:#85bb50; 
--pink_A400:#dd0031; 
--light_green_501:#8cc84b; 
--yellow_901:#f58219; 
--yellow_900:#da802a; 
--deep_orange_800:#c54e2d; 
--teal_801:#00804f; 
--gray_905:#1f1315; 
--gray_906:#043321; 
--gray_903:#212121; 
--gray_904:#292929; 
--gray_501:#959595; 
--gray_500:#a6a6a0; 
--gray_901:#232323; 
--gray_902:#1c1c1c; 
--blue_800:#2d72b8; 
--gray_900:#191919; 
--teal_50:#d4edf1; 
--green_50:#ebebe2; 
--gray_100:#f2f3f6; 
--indigo_300:#5294cf; 
--cyan_100:#aedaee; 
--black_900_72:#00000072; 
--bluegray_300:#8898a8; 
--cyan_901:#1a476f; 
--blue_400:#43afde; 
--cyan_900:#00416b; 
--light_blue_A100:#74d0ff; 
--blue_A200:#3395ff; 
--light_green_400:#9ec656; 
--light_blue_901:#00678c; 
--light_blue_900:#125d85; 
--deep_orange_700:#d36531; 
--deep_orange_701:#e44d26; 
--light_green_800:#599636; 
--deep_orange_300:#eb996e; 
--red_A700:#ed0006; 
--gray_600:#797979; 
--gray_900_66:#19191966; 
--amber_700:#f9a000; 
--gray_200:#e9e9e9; 
--gray_201:#ebebeb; 
--white_A700:#ffffff; 
--cyan_A400:#00e1e1; 
--red_902:#c3002f; 
--red_901:#a50032; 
--red_904:#7a0c00; 
--red_903:#a41e11; 
--red_900:#bc0033; 
--light_blue_402:#2ab7ff; 
--light_blue_401:#17b5eb; 
--light_blue_400:#20c2ef; 
--teal_200:#7cb7d5; 
--green_A400:#00d985; 
--green_A401:#13d48a; 
--black_900:#000000; 
--deep_orange_600:#eb5424; 
--light_green_700:#5da831; 
--deep_orange_201:#ffbe9d; 
--deep_orange_200:#eebd9d; 
--red_905:#ad2115; 
--gray_700:#644659; 
--teal_A402:#3dffb4; 
--gray_301:#e0e0e0; 
--blue_601:#3894c3; 
--orange_A700:#ff5e00; 
--blue_600:#3899c6; 
--gray_300:#dddddd; 
--teal_A401:#2cffa7; 
--teal_A400:#016CD9; 
--bluegray_900:#2c2c2c; 
--cyan_300:#59b6da; 
--indigo_100:#b9c9d9; 
--bluegray_502:#4e7896; 
--bluegray_501:#5283a1; 
--bluegray_500:#5d7793; 
--bluegray_906:#2e2e2e; 
--blue_200:#8fd8f9; 
--bluegray_905:#353535; 
--bluegray_904:#072654; 
--bluegray_903:#263238; 
--bluegray_902:#2e353a; 
--indigo_500:#367ba5; 
--bluegray_901:#303030; 
--primary:#0A53E6;
--warning : #F4BD00;
--secondary : #272727;
--error : #e5484d;
--cw_shade: #D9D9D9;
--fill : #191919;
--core : #f8fafc;
--subtle : #CCCCCC;
--hint : #969696;
}