* {
  box-sizing: border-box;
  line-height: normal;
}
body {
  font-family: "Roboto", sans-serif !important;
  margin: 0;
  padding: 0;
  zoom : 100%;
 }
.dhiwise-code,
.dhiwise-navigation {
  min-height: 100%;
}
.common-pointer {
  cursor: pointer;
}
.dhiwise-navigation {
  padding: 20px;
  background-color: #232323;
}
.dhiwise-navigation h1 {
  color: white;
}
.dhiwise-navigation .headline {
  font-size: 14px;
  margin-bottom: 10px;
  color: white;
}
.dhiwise-navigation ul {
  padding: 0;
}
.dhiwise-navigation ul li {
  list-style: none;
  margin-right: 20px;
  font-size: 16px;
  font-weight: bold;
}
.dhiwise-navigation a {
  color: #132cdc;
}
table {
  border-spacing: 0;
  width: 100%;
}
.common-select {
  z-index: 10000;
}
.react-datepicker-popper {
  z-index: 100000 !important;
}
.drawable-icon{
  position: absolute;
  margin: auto;
  z-index: 1000;
}
.input-wrap{
  position: relative;
}
option{
  color: #000;
}
.table-wrap{
  overflow: auto;
}
input:focus{
  outline:none;
}
.ReactModal__Body--open{
  overflow: hidden;
}
  .slider-indicator:first-child {
    margin-left: 0;
}

.slider-indicator:last-child {
margin-right: 0;
}

.rc-drawer-content-wrapper{
  width: 100% !important;
}
.rc-drawer-right .rc-drawer-content-wrapper{
  display: flex;
  justify-content: flex-end;
}
.rc-drawer-left .rc-drawer-content-wrapper{
  display: flex;
  justify-content: flex-start;
}
.ReactModal__Overlay{
  z-index: 1051;
}

ul li a{
  display: block;
}

.mobile-menu div{
  height: 1.5px;
  width: 20px;
  background-color: #000;
  margin:4px 0;
}
.mobile-menu {
  display: none;
  cursor: pointer;
}
.header-row{
  align-items:center;
  justify-content: space-between;
  display: flex;
}
@media only screen and (min-width: 200px) and (max-width: 550px)  {
  .mobile-menu {
    display: block;
  }
  .header-row{
    width: 100% !important;
  }
  .display-block{
    display: block;
  }
  .display-none{
    display: none;
  }
  .common-row-list > ul{
    padding-left: 10px;
    padding-right: 10px;
    flex-wrap: wrap;
  }
}
.menu-close-icon {
  position: absolute;
  right: 16px;
  top: 16px;
}
.menu-overlay{
  width: 100%;
  height: 100%;
  background-color: #000;
  opacity: 0.7;
  position: fixed;
  left: 0px;
  top: 0px;
}
.close-line1{
  transform: translate(0, 6px) rotate(-45deg) skewX(0) skewY(0) scaleX(1) scaleY(1);
}
.close-line2{
  transform: translate(0, 0) rotate(45deg) skewX(0) skewY(0) scaleX(1) scaleY(1);
}
.common-row-list > ul{
  display: flex;
  flex-direction: row;
  list-style-type: none;
  flex-grow: 1;
}

.common-column-list > ul{
  display: flex;
  flex-direction: column;
  list-style-type: none;
}

.otpInput {
  width: 3rem !important;
  background-color: #1C1C1C !important;
  color: white;
  height: 3rem;
  margin: 0 1rem;
  font-size: 2rem;
  font-weight: bolder;
  text-align: center;
  border-radius: 10px !important;
  }

  .otp-container {
    flex-grow: 4;
    flex-shrink: 1;
    flex-basis: calc(var(--modifier) * 999);
  }

  /* our-home css */
  .account-dot-container {
    position: relative;
  }
  .account-control-container {
    position: absolute;
    bottom: 0rem;
    left: 20rem;
    }

  .fitZoomToScreen {
    zoom: 100% !important;
  }
 
    .carousel-dots {
      list-style: none;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    
    .carousel-dots li {
      width: 8px;
      height: 8px;
      background-color: #959595;
      border-radius: 50%;
    }
    
    .carousel-dots li.active {
      position: relative;
      margin-right: 10px;
    }
    
    .carousel-dots li.active:before {
      content: "";
      position: absolute;
      left: -20px; 
      width: 30px; 
      height: 8px; 
      background-color: #f8fafc; 
      border-radius: 50px;
      animation: slide 0.5s forwards;
    }

    .preloader{
      width: 100vw;
      height: 100vh;
      position: fixed;
      top: 0px;
      left: 0px;
      align-items: center;
      justify-content: center;
      background-color: #111111;
      z-index: 1111;
  }
  .preloader img{
      width: 140px;
  }
  .loader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    z-index: 99999;
  }
  .display-none {
    display: none;
  }
  .display-block {
    display: block;
  }
  .loader-modal {
    position: fixed;
    background: transparent;
    color: #376a37;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .app-container {
    position: relative;
    background-image: url("../../public/images/background solid.png");
    background-repeat: no-repeat;
    background-size: 100% 400px;
    background-attachment: fixed;
  }
  .static-stepper {
    position: sticky;
    top: 0px;
    z-index: 2;
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 8px;
    background-image:  url('../../public/images/background solid.png');
    background-size: 100% 400px;
    background-repeat: no-repeat;
    background-attachment: fixed; 
  }
  .process-stepper {
    border-radius: 14px;
    line-height: 1;
    height: 80px;
    flex-shrink: 0;
    display: flex;
    align-items: center;
  }
  
  /* @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
    .process-stepper {
      -webkit-backdrop-filter: blur(10px);
      backdrop-filter: blur(10px);
      background-color: rgba(255, 255, 255, 0.1);  
    }
    .warning {
      display: none;
    }
  } */
  .bg-blue-gradient {
  background: linear-gradient(180deg, rgba(10, 83, 230, 0.16) 0%, rgba(0, 0, 0, 0) 100%),
  radial-gradient(73.53% 41.48% at 50.14% 100%, #96CAFF 0%, #016CD9 100%);
  }
  .active-menu-item {
    border-right: 5px solid gray;
    -moz-outline-radius-topright: 8px solid blue;
  }

.slide-container {
  position: relative;
  left: -600px;
  width: 600px;
  -webkit-animation: slide 1s forwards;
  -webkit-animation-delay: 200ms;
  animation: slide 1s forwards;
  animation-delay: 200ms;
}

@-webkit-keyframes slide {
  100% { left: 0; }
}

@keyframes slide {
  100% { left: 0; }
}

@keyframes fadeIn {
  0% {opacity: 0;}
  50% {opacity: 0;}
  100% {opacity: 1;}
}

.fadeIn {
  animation: fadeIn ease 500ms;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  flex-shrink: 0;
  overflow: hidden;
}
.ReactCollapse--collapse {
  transition: height 500ms;
  width: 100%;
  gap:"20px";
  display: flex;
  flex-direction: column;
}

@keyframes slide-down {
  0% {
    transform: translateY(-100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slide-up {
  0% {
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    transform: translateY(-100%);
    opacity: 0;
  }
}

.animate-slide-down {
  animation: slide-down 1s ease-in-out forwards;
}

.animate-slide-up {
  animation: slide-up 1s ease-in-out forwards;
}