
@tailwind base;
* {
  scrollbar-width: thin;
  scrollbar-color: var(--secondary-color) var(--primary-color);
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 11px;
  height: 11px;
}

*::-webkit-scrollbar-track {
  background: var(--primary-color);
}

*::-webkit-scrollbar-thumb {
  background-color: #9d9f9f;
}
*::-webkit-scrollbar-thumb:hover {
  background-color: var(--secondary-color);
}
@tailwind components;
@tailwind utilities;

:root {
  --primary-color: #232323;
  --secondary-color : #8C8D8E;
}
/* Override default focus colors for tailwindcss-forms https://github.com/tailwindlabs/tailwindcss-forms */
[type='text']:focus,
[type='email']:focus,
[type='url']:focus,
[type='password']:focus,
[type='number']:focus,
[type='date']:focus,
[type='search']:focus,
[type='checkbox']:focus,
[type='radio']:focus,
[multiple]:focus,
textarea:focus,
select:focus {
  --tw-ring-color: var(--primary-color);
  border-color: var(--primary-color);
}

.react-datepicker-wrapper .react-datepicker__input-container input{
  background-color: transparent;
  border: none;
  width: 100%;
  padding: 0px;
}
.react-datepicker__day.react-datepicker__day--keyboard-selected {
  background: none;
  color: black;
}
input:focus, textarea:focus{
  outline:none !important;
  outline-offset: 0 !important;
  --tw-ring-offset-width:none !important;
}

li:focus-visible{
  outline: none;
}

li:hover{
  cursor: pointer;
}

.tab-panel--selected { animation-name: tabPanel; animation-duration: 300ms; animation-timing-function: linear; }
@keyframes tabPanel { from { opacity: 0; } to { opacity: 1; } }
/* Input style */
.input-container {
  position: relative;
  display: flex;
  flex-direction: column;
}

.input-container label {
  position: absolute;
  transform-origin: top left;
  transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
}

.input-container input {
  transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
}

/* our-home css */
.project-type-button {
 color: white;
}

.active-project-type-button {
  background-color: #DDDDDD;
  color: black;
}

/* select model one css  */
.model-container:hover {
  cursor: pointer;
  background-color: black;
}
.model-container:focus-visible{
 background-color: #f15959;
 border: 1px solid #075537;
}

.active-navigation-btn {
  background-color: white;
}

.navigation-btn {
  background-color: green;
}
.data-label-left {
  position: relative;
  cursor: pointer;
}
.data-label-left:hover::before {
  content: attr(data-label);
  position: absolute;
  top: 50%;
  right: calc(100% + 8px);
  transform: translateY(-50%);
  background-color: #272727;
  color: #969696;
  font-size: 0.875rem;
  padding: 0.5rem 1rem;
  border-radius: 0.25rem;
  white-space: nowrap;
  border: 1px solid #4E4E4E;
}
.data-label-left:hover::after {
  content: '';
  position: absolute;
  top: 50%;
  right: calc(100% + 4px);
  transform: translateY(-50%) rotate(45deg);
  width: 8px;
  height: 8px;
  background-color: #272727;
  border-right: 1px solid #4E4E4E;
  border-top: 1px solid #4E4E4E;
}
.data-label-right {
  position: relative;
  cursor: pointer;
}
.data-label-right:hover::after {
  content: attr(data-label);
  position: absolute;
  top: 50%;
  left: calc(100% + 8px);
  transform: translateY(-50%);
  background-color: #272727;
  color: #969696;
  font-size: 0.875rem;
  padding: .5rem 1rem;
  border-radius: 0.25rem;
  white-space: nowrap;
  border: 1px solid #4E4E4E;
}
.data-label-right:hover::before {
  content: '';
  position: absolute;
  top: 50%;
  left: calc(100% + 4px);
  transform: translateY(-50%) rotate(45deg);
  width: 10px;
  height: 10px;
  background-color: #272727;
  border-left: 1px solid #4E4E4E;
  border-bottom: 1px solid #4E4E4E;
  z-index: 10;
}
.data-label-bottom {
  position: relative;
  cursor: pointer;
}

.data-label-bottom:hover::after {
  content: attr(data-label);
  position: absolute;
  top: calc(100% + 8px);
  left: 50%;
  transform: translateX(-50%);
  background-color: #272727;
  color: #969696;
  font-size: 0.875rem;
  padding: 0.5rem 1rem;
  border-radius: 0.25rem;
  white-space: nowrap;
  border: 1px solid #4E4E4E;
}
